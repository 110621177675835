import React from 'react';
import { FaCalendarCheck, FaTasks, FaSyncAlt } from 'react-icons/fa';

const Features = () => (
  <section className="bg-slate-950 py-12 rounded-lg shadow-lg">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-6 text-white">Features</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-6">
        <div className="p-6 bg-white rounded-lg shadow-md">
          <FaCalendarCheck className="text-4xl mx-auto mb-4 text-blue-500" />
          <h3 className="text-2xl font-semibold mb-2">iCal Integration</h3>
          <p>Seamlessly integrate with your email provider’s calendar and take your tasks everywhere.</p>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-md">
          <FaTasks className="text-4xl mx-auto mb-4 text-green-500" />
          <h3 className="text-2xl font-semibold mb-2">Task Management</h3>
          <p>Organize, update, and complete your tasks efficiently.</p>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-md">
          <FaSyncAlt className="text-4xl mx-auto mb-4 text-red-500" />
          <h3 className="text-2xl font-semibold mb-2">GPT-4 Integration</h3>
          <p>Drop in large texts to receive actionable tasks and suggested times instantly.</p>
        </div>
      </div>
    </div>
  </section>
);

export default Features;
