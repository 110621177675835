import { useEffect, useState } from "react"
import LoginForm from "../Login/LoginForm"
import Background from '../Landing/Background'

const heroSections = [
    {
        "id": 1,
        "headline": "Effortlessly Turn Emails and Chats into Tasks",
        "subHeadline": "Sync Seamlessly with Your Calendar",
        "cta": "Get Started for Free — No Credit Card Required!"
    },
    {
        "id": 2,
        "headline": "Transform Your Workflow with AI-Driven Task Management",
        "subHeadline": "Integrate with Any Email and Calendar System",
        "cta": "Join Thousands Who've Boosted Productivity with AccioTask"
    },
    {
        "id": 3,
        "headline": "From Communication to Completion: Streamline Your Tasks Today",
        "subHeadline": "Real-Time Synchronization Across All Your Devices",
        "cta": "Trusted by Professionals Across Industries — See Success Stories"
    },
    {
        "id": 4,
        "headline": "Never Miss a Task — Let AI Do the Heavy Lifting",
        "subHeadline": "Perfect for Teams: Share and Collaborate Effortlessly",
        "cta": "Secure and Reliable: Your Data’s Safe With Us"
    },
    {
        "id": 5,
        "headline": "Instant Task Creation from Any Email or Message",
        "subHeadline": "Seamless Integration with Your Existing Workflow",
        "cta": "Try It Out Now — Instant Setup in Less Than a Minute!"
    }
]


const Hero = () => {
    const [isRegistered, setIsRegistered] = useState(false)
    const [heroIndex, setHeroIndex] = useState(0)
    useEffect(() => {
        setHeroIndex(Math.floor(Math.random() * heroSections.length));
    }, [])

    return (
        <div className='flex flex-col w-full h-full '>
            <div className="flex flex-col justify-center items-center h-full w-full">
                <div className="flex flex-col space-y-4 sm:space-y-6 h-full py-4">
                    <p className="text-5xl sm:max-w-6xl md:text-6xl p-2 font-bold">{heroSections[heroIndex].headline}</p>
                    <div className="space-y-1">
                        <p className="text-2xl p-2">{heroSections[heroIndex].subHeadline}</p>
                        <p className="text-l">{heroSections[heroIndex].cta}</p>
                    </div>
                </div>
                <div className="w-full max-w-lg p-2 my-2">
                    <LoginForm setIsRegistered={setIsRegistered} isRegistered={isRegistered} />
                </div>
            </div>
            <Background />
        </div>
    )
}

export default Hero