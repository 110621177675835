import React from 'react';

import { useNavigate } from 'react-router-dom';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { yellow } from '@mui/material/colors';

import AccountMenu from './Account/AccountMenu';

import { ReactComponent as Title } from '../title.svg'
import { ReactComponent as Logo } from '../logo.svg'

const Navbar = ({ username, premium = false }) => {
    const navigate = useNavigate();
    return (
        <div className="title flex justify-between items-center px-6 h-14 bg-white">
            <button onClick={() => { navigate('/') }} className="flex right-0 h-8 min-w-3/5 items-center">
                <Logo />
                <Title />
                {premium ? <WorkspacePremiumIcon sx={{color: yellow[700]}} /> : null}
            </button>
            {username &&
                <div className="flex space-x-4 items-center left-0">
                    {/* <IconButton onClick={() => { setTaskInputModal(true) }}>
                        <AddTaskIcon color='black' sx={{ color: 'green' }} />
                    </IconButton> */}
                    <div>
                        <AccountMenu username={username} />
                    </div>
                </div>
            }
        </div>
    );
};

export default Navbar;
