import { useEffect, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import InputBar from '../components/InputBar/InputBar';
import FloatingInputButton from '../components/InputBar/FloatingInputBarButton';
import Navbar from '../components/Navbar';
import Tasks from '../containers/Tasks';
import SettingsBar from '../components/SettingsBar/SettingsBar';
import ErrorMessage from '../components/Messages/ErrorMessage';
import SuccessMessage from '../components/Messages/SuccessMessage';
import CircularIndeterminate from '../components/Common/Common';

const Service = () => {
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [userEmail, setUserEmail] = useState(null);
    const [username, setUsername] = useState(null);
    const [hasPremium, setHasPremium] = useState(false);

    const [showCompletedTasks, setShowCompletedTasks] = useState(false);
    const [inputTaskOpen, setInputTaskOpen] = useState(false);
    const handleTaskClose = () => {
        setInputTaskOpen(false);
    };

    // preferences
    const [view, setView] = useState(2);
    const [formPreference, setFormPreference] = useState('standard');

    const [newTasks, setNewTasks] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [categorizedTasks, setCategorizedTasks] = useState([]);
    const [loadingTasks, setLoadingTasks] = useState(false);
    const [collapsedIndexes, setCollapsedIndexes] = useState([]);

    const toggleCollapse = (index) => {
        if (collapsedIndexes.includes(index)) {
            setCollapsedIndexes(collapsedIndexes.filter(i => i !== index));
        } else {
            setCollapsedIndexes([...collapsedIndexes, index]);
        }
    };

    useEffect(() => {
        setLoadingTasks(true);
        axios.get(`${process.env.REACT_APP_API_URL}/user/data`, { withCredentials: true })
            .then(response => {
                const { tasks, userEmail, username, view, formPreference, premium } = response.data;
                setTaskList(tasks);
                setUserEmail(userEmail);
                setUsername(username);
                setView(view);
                setFormPreference(formPreference);
                setHasPremium(premium);
            })
            .catch(err => {
                console.error(err);
                navigate('/landing');
            })
            .finally(() => {
                setLoadingTasks(false);
            });
    }, []);


    useEffect(() => {
        if (newTasks.length != 0) {
            setTaskList(() => {
                return [...taskList, ...newTasks]
            })
        }
    }, [newTasks])

    const [taskUpdatedId, setTaskUpdatedId] = useState(null);

    useEffect(() => {
        if (taskUpdatedId) {
            (async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/tasks/${taskUpdatedId}`,
                        {
                            withCredentials: true
                        }
                    );

                    const newTasksList = taskList.filter(task => task.id !== taskUpdatedId);

                    if (!response.data) {
                        setTaskList(newTasksList);
                        setSuccess('Task successfully deleted!')
                    } else {
                        console.log(response.data)
                        setTaskList([...newTasksList, response.data]);
                        setSuccess('Successfully edited task!')
                    }

                } catch (error) {
                    // Handle error
                    console.log(error)
                    setError('There was an error, please try again later.')
                }
            })();
        }
        setTaskUpdatedId(null)
    }, [taskUpdatedId]);

    return (
        <div className='w-full min-h-screen bg-slate-100 z-0'>
            {
                error && <ErrorMessage message={error} />
            }
            {
                success && <SuccessMessage message={success} setMessage={setSuccess} />
            }
            <InputBar open={inputTaskOpen} handleClose={handleTaskClose} setNewTasks={setNewTasks} formPreference={formPreference} />
            <div className='w-full'>
                <div className='sticky top-0 w-full z-50'>
                    <Navbar username={username} premium={hasPremium} setTaskInputModal={setInputTaskOpen} />
                    <div className="h-8">
                        <SettingsBar showCompletedTasks={showCompletedTasks} setShowCompletedTasks={setShowCompletedTasks} view={view} setView={setView} />
                    </div>
                </div>
                <div className="relative flex flex-1">
                    {loadingTasks && (
                        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-70 h-screen">
                            <CircularIndeterminate />
                        </div>
                    )}

                    {/* Other Content */}
                    {!loadingTasks && (
                        <Tasks
                            view={view}
                            setView={setView}
                            taskList={taskList}
                            setTaskList={setTaskList}
                            setTaskUpdatedId={setTaskUpdatedId}
                            showCompletedTasks={showCompletedTasks}
                            setInputTaskOpen={setInputTaskOpen}
                        />
                    )}
                </div>
            </div>
            <div className="fixed bg-transparent bottom-0 right-0 z-50 pr-4 pb-4">
                <FloatingInputButton setTaskInputModal={setInputTaskOpen} />
            </div>
        </div >
    )
}

export default Service