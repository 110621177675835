import React from 'react';

const Benefits = () => (
    <section className="py-12 px-2 md:px-12 bg-slate-950 rounded-lg">
        <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6 text-white">Benefits</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                    <h3 className="text-2xl font-semibold mb-2">For Business Owners</h3>
                    <p>Optimize your team's productivity with a tool that scales as you grow. AccioTask enhances your ability to manage tasks across your organization, ensuring everyone is aligned and efficient.</p>
                    <p className="mt-2">AccioTask is a cost-effective solution for your business needs.</p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                    <h3 className="text-2xl font-semibold mb-2">For Professionals and Executives</h3>
                    <p>Master your schedule with AccioTask, where efficiency meets innovation. Seamlessly integrate with your existing calendar and email systems to streamline your task management.</p>
                    <p className="mt-2">Trust in a platform that enhances your operational efficiency. Discover why top executives rely on AccioTask through our featured testimonials.</p>
                </div>
            </div>
        </div>
    </section>
);

export default Benefits;
