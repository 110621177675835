import { useEffect, useState } from 'react';
import axios from 'axios';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Done from '@mui/icons-material/Done';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const SettingsBar = ({ showCompletedTasks, setShowCompletedTasks, view, setView }) => {
    const [viewButton, setViewButton] = useState(null)
    const [viewAriaLabel, setViewAriaLabel] = useState(null)

    const handleShowCompletedTasks = (event, newShowCompletedTasks) => {
        setShowCompletedTasks(newShowCompletedTasks);
    };

    const updatePreferences = (updatedFields) => {
        // Define the data object with the fields to update

        axios.post(
            `${process.env.REACT_APP_API_URL}/user/preferences/update`,
            { ...updatedFields }, // Pass the updatedFields object as the request body
            {
                withCredentials: true,
            }
        )
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        switch (view) {
            case 1:
                setViewButton(<ViewModuleIcon />);
                setViewAriaLabel('Tile View')
                break;
            case 2:
                setViewButton(<ViewListIcon />);
                setViewAriaLabel('Table View')
                break;
            default:
                // Set a default value or handle other cases
                setViewButton(null);
                break;
        }
    }, [view]);

    const handleViewButton = () => {
        // Toggle between 1 and 2
        const updatedView = view === 1 ? 2 : 1;
        setView(updatedView);

        updatePreferences({
            view_mode: updatedView
        });
    };

    return (
        <div className="flex h-full justify-center py-1 bg-white gap-2">
            <ToggleButtonGroup
                value={showCompletedTasks}
                exclusive
                onChange={handleShowCompletedTasks}
                aria-label="Show Completed Tasks"
            >
                <ToggleButton
                    value={!showCompletedTasks}
                    aria-label={showCompletedTasks ? "Hide" : "Show"}
                    style={{
                        backgroundColor: !showCompletedTasks ? 'gray' : 'green',
                    }}
                >
                    {
                        !showCompletedTasks ?
                            <RemoveDoneIcon />
                            :
                            <Done />
                    }
                </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewButton}
                aria-label="Change View"
            >
                <ToggleButton
                    value={view}
                    aria-label={viewAriaLabel}
                    style={{
                        backgroundColor: 'white',
                    }}
                >
                    {
                        (viewButton)
                    }
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
};

export default SettingsBar;
