import React from 'react'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Edit from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';

const TaskModal = ({ id, anchorEl, open, handleClose, handleTaskDelete, handleTaskEdit }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="task-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={() => { handleTaskDelete(id); handleClose(); }}>
                <DeleteIcon /> Delete
            </MenuItem>
            <MenuItem onClick={() => { handleTaskEdit(); handleClose(); }}>
                <Edit /> Edit
            </MenuItem>
        </Menu>
    )
}

export default TaskModal