import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { TextareaAutosize } from "@mui/material"
import CircularIndeterminate from "../../Common/Common";
import AddTaskIcon from '@mui/icons-material/AddTask';
import SubmitButton from "../../Buttons/Buttons";



const CreateAITask = ({ setError, setSuccess, setNewTasks, handleClose }) => {
    const [loading, setLoading] = useState(false);
    const [taskInput, setTaskInput] = useState('');
    const [prevTaskInput, setPrevTaskInput] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (taskInput !== prevTaskInput) {
            setLoading(true)
            setPrevTaskInput(taskInput)
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            try {
                // Send a POST request with the task data and user's time zone
                await axios.post(`${process.env.REACT_APP_API_URL}/tasks/create-ai`,
                    {
                        task: taskInput,
                        timezone: userTimeZone, // Add timezone to the data object
                    },
                    {
                        withCredentials: true
                    })
                    .then(response => {
                        const parsedTasks = response.data.task;
                        setNewTasks(parsedTasks);
                        setTaskInput('');
                        handleClose();
                        setSuccess(`${parsedTasks.length} new ${parsedTasks.length === 1 ? 'task' : 'tasks'} created.`);
                    });
            } catch (err) {
                console.log(err)
                // Check for 403 status code
                setError("Error creating tasks.")
                if (err.response && err.response.status === 403) {
                    try {
                        const billingResponse = await axios.get(
                            `${process.env.REACT_APP_API_URL}/user/billing`,
                            {
                                withCredentials: true
                            }
                        );

                        // Use the URL from the billingResponse to navigate
                        if (billingResponse.data && billingResponse.data.url) {
                            window.location.href = billingResponse.data.url;
                        }

                    } catch (billingError) {
                        console.log("Error fetching billing URL: ", billingError);
                    }
                }
            }
        } else {
            setError("Identical tasks cannot be submitted concurrently.")
        }
        setLoading(false)
    }

    return (
        <div className="flex flex-col justify-between w-full sm:max-w-lg bg-white border-gray-300 rounded-md p-2" style={{ minHeight: '250px' }}>
            <form
                id='task-input-container'
                className="flex flex-col w-full sm:max-w-lg items-center justify-center bg-white border-gray-300 rounded-md p-2 space-y-2"
                onSubmit={handleSubmit}
            >
                <div className="flex-grow w-full">
                    <TextareaAutosize
                        minRows={5}
                        maxRows={15}
                        type="text-area"
                        spellCheck='false'
                        id='task-input'
                        maxLength={650}
                        value={taskInput}
                        required={true}
                        onChange={(e) => { setTaskInput(e.target.value) }}
                        className="w-full outline-none px-2 overflow-y-auto"
                        placeholder="Describe some tasks..."
                        style={{ maxHeight: '200px', minHeight: '200px', resize: 'none' }}
                    />
                </div>
                <SubmitButton loading={loading} />
            </form>
        </div>
    )
}

export default CreateAITask