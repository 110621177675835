import React, { useEffect, useState } from 'react';

const ErrorMessage = ({ message, setMessage }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            try { setMessage(null) } catch {  }
        }, 2500);  // 5 seconds delay before fading out
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`fixed flex justify-center items-center top-4 left-0 w-full text-white transition-all ${visible ? 'opacity-100' : 'opacity-0 -top-96'}  z-[150]`}>
            <p className='rounded-lg w-5/6 p-4 bg-red-500'>{message}</p>
        </div>
    );
};

export default ErrorMessage;
