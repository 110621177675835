import axios from 'axios';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextField, Button } from '@mui/material';
import Email from '@mui/icons-material/Email';
import Lock from '@mui/icons-material/Lock';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ErrorMessage from '../Messages/ErrorMessage';
import SuccessMessage from '../Messages/SuccessMessage';

import CircularIndeterminate from '../Common/Common';

import validateEmail from '../../helpers/email'

const LoginForm = ({ isRegistered = false, setIsRegistered }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [usernameOrEmail, setUsernameOrEmail] = useState('')

  const [showPasswordField, setShowPasswordField] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();

  const handleUsernameClick = () => {
    setShowPasswordField(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    setError(null)

    if (!isRegistered) {
      if (validateEmail(email)) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/landing/register`,
            {
              username: email, // there is no need for username
              email,
              password
            }
          );
          // Handle success response, e.g., show a success message or redirect to another page
          setSuccess('Success creating user!')
          setUsernameOrEmail(username)
          setIsRegistered(true)
        } catch (error) {
          console.error('Registration failed:', error.response.error);
          // Handle error response, e.g., show an error message to the user
          setError(`${JSON.stringify(error.response.data.error).replaceAll('"', '')}`)
        }
      } else {
        setError('Email needs to be a valid email address.')
        return
      }
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/landing/login`, {
          usernameOrEmail,
          password
        });
        // Handle the response
        // Process the JWT or other response data
        setSuccess('Success! Redirecting...')
        navigate('/')
      } catch (error) {
        console.error('Error logging in:', error.response.data.error);
        setError(`${JSON.stringify(error.response.data.error).replaceAll('"', '')}`)
      }
    };
    setIsLoading(false)
  }

  return (
    <div>
      {
        error && <ErrorMessage message={error} />
      }
      {
        success && <SuccessMessage message={success} setMessage={setSuccess} />
      }
      <form onSubmit={handleSubmit} className="p-2">
        <div className="bg-white border rounded-lg p-2 ">
          {!isRegistered ? (
            <>
              {/* <TextField
                label="Username"
                type="text"
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="username"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: <AccountCircle sx={{ marginRight: '4px' }} />,
                }}
              /> */}
              <TextField
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="you@awesome.com"
                required
                value={email}
                onClick={handleUsernameClick}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: <Email sx={{ marginRight: '4px' }} />,
                }}
              />
            </>
          ) : (
            <TextField
              label="Email"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="email"
              required
              onClick={handleUsernameClick}
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              InputProps={{
                startAdornment: <AccountCircle sx={{ marginRight: '4px' }} />,
              }}
            />
          )}
          {<div className={`transition-opacity duration-300 delay-75 ${showPasswordField ? 'opacity-100' : 'opacity-0 invisible h-0'}`}>
            <TextField
              label="Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="•••••••"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: <Lock sx={{ marginRight: '4px' }} />,
              }}
            />
          </div>}
          <div className="flex justify-center mt-4">
            {
              isLoading ? <CircularIndeterminate /> : <>
                {isRegistered ? <Button type="submit" variant="contained" color="secondary">
                  Login
                </Button> :
                  <Button type="submit" variant="contained" color="primary">
                    Sign Up
                  </Button>}
              </>
            }
          </div>
        </div>
        <div className="flex justify-center mt-4 p-4">
          <button className='text-sm underline' type='button' onClick={() => { setIsRegistered(!isRegistered) }}>
            {
              isRegistered ? <><p className='inline'>Don't have an account?</p> <b>Register</b></>
                :
                <><p className='inline'>Already have an account?</p> <b>Login</b></>
            }
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
