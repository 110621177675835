import { useEffect, useState } from 'react';
import TasksTilesView from '../components/Tasks/Views/TasksTilesView';
import TasksTableView from '../components/Tasks/Views/TasksTableView';

import IconButton from '@mui/material/IconButton';
import AddTaskIcon from '@mui/icons-material/AddTask';

const Tasks = ({
    view = 2,
    setView,
    taskList,
    setTaskUpdatedId,
    showCompletedTasks = false,
    setInputTaskOpen,
}) => {
    const [displayedTasks, setDisplayedTasks] = useState([]);
    const [displayedContent, setDisplayedContent] = useState(null);

    useEffect(() => {
        setDisplayedTasks(prevDisplayedTasks => {
            const filteredTasks = taskList.filter((task) => task.completed === showCompletedTasks);
            return filteredTasks;
        });
    }, [taskList, showCompletedTasks]);

    useEffect(() => {
        const setTasksView = () => {
            switch (view) {
                case 1:
                    return (
                        <TasksTilesView
                            taskList={displayedTasks}
                            setTaskUpdatedId={setTaskUpdatedId}
                            showCompletedTasks={showCompletedTasks}
                        />
                    );
                case 2:
                    return (
                        <TasksTableView
                            taskList={displayedTasks}
                            setTaskUpdatedId={setTaskUpdatedId}
                            showCompletedTasks={showCompletedTasks}
                        />
                    );
            }
        };
        setDisplayedContent(setTasksView);
    }, [view, displayedTasks, setView, setTaskUpdatedId, showCompletedTasks]);

    return (
        <div className='w-full'>
            {displayedTasks.length !== 0 ? (
                displayedContent
            ) : (
                <>
                    No Tasks here. Create one by clicking
                    <IconButton
                        onClick={() => {
                            setInputTaskOpen(true);
                        }}
                    >
                        <AddTaskIcon fontSize="small" sx={{ color: 'green' }} />
                    </IconButton>
                </>
            )}
        </div>
    );
};

export default Tasks;
