import React from 'react';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TodayIcon from '@mui/icons-material/Today';
import magicPrompt from '../../assets/magic-prompt.png'; // Adjust the path as needed
import synchedCalendar from '../../assets/synched-calendar.png'; // Adjust the path as needed
import tileTaskView from '../../assets/tile-task-view.png'; // Adjust the path as needed

const HowItWorks = () => (
    <section className="bg-gray-100 py-12 px-4 text-center">
        <h2 className="text-3xl font-bold mb-6">How It Works</h2>
        <div className="flex flex-wrap justify-center items-center">
            <div className="max-w-sm m-4">
                <img src={magicPrompt} alt="Magic AI Prompt" className="mb-2" />
                <div className="flex justify-center mb-2">
                    <RecordVoiceOverIcon style={{ fontSize: '48px' }} />
                </div>
                <p>Start by typing your day's tasks and meetings as plain text into the Magic AI prompt.</p>
            </div>
            <div className="max-w-sm m-4">
                <img src={tileTaskView} alt="AccioTask Dashboard" className="mb-2" />
                <div className="flex justify-center mb-2">
                    <AutorenewIcon style={{ fontSize: '48px' }} />
                </div>
                <p>AccioTask relies on GPT-4 to analyze your input and smartly suggests task separations and times.</p>
            </div>
            <div className="max-w-sm m-4">
                <img src={synchedCalendar} alt="Tasks Synced to Calendar" className="mb-2" />
                <div className="flex justify-center mb-2">
                    <TodayIcon style={{ fontSize: '48px' }} />
                </div>
                <p>All tasks neatly organized and synced to your emails calendar, which can be edited and deleted from within AccioTask.</p>
            </div>
        </div>
    </section>
);

export default HowItWorks;
