import React from 'react'



import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from "@mui/material/IconButton";

import Done from "@mui/icons-material/Done";
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

const TaskTile = ({ id, completed, title, description, start, end, date, created, editMode, handleClick, saveTaskEdit, setTaskTitle, setTaskDescription, updateTaskField, handleDateTimeChange, handleTaskUpdated }) => {

    const TaskField = ({ field, value, color }) => {
        return (
            <div className={`bg-gray-100 rounded-md p-2`}>
                <div className="flex flex-wrap justify-between items-center">
                    <p className={`bg-opacity-40 rounded-md p-2`}>{field}: </p>
                    <p className={`rounded-md p-2`}>{value}</p>
                </div>
            </div>
        )
    }

    return (
        <div key={id} className="w-full min-h-full flex flex-col p-2 relative" onDoubleClick={editMode ? null : handleClick}>
            <div className="flex-grow flex flex-col justify-center items-center px-1 z-0">
                <div className="flex flex-col items-center w-full">
                    <div className="group w-full pt-2 rounded transition-all duration-300 ease-in-out">
                        <div className="flex flex-col h-[calc(3rem+1.75rem)] justify-start">
                            {editMode ? (
                                <>
                                    <input
                                        className="text-lg font-semibold text-center w-full mb-2"
                                        value={title}
                                        placeholder='Task Title'
                                        onChange={(event) => { updateTaskField(event, setTaskTitle, 'title') }}
                                        required
                                    />
                                    <input
                                        className="text-sm text-gray-500 text-center w-full"
                                        value={description}
                                        placeholder='Task Description'
                                        onChange={(event) => { updateTaskField(event, setTaskDescription, 'description') }}
                                    />
                                </>
                            ) : (
                                <div className='z-10'>
                                    <h2 className={`text-xl font-semibold ${description ? 'line-clamp-2' : 'line-clamp-none'} hover:line-clamp-none`}>
                                        {title}
                                    </h2>
                                    {description && (
                                        <p className="text-sm line-clamp-1 hover:line-clamp-none">{description}</p>
                                    )}
                                </div>
                            )}
                        </div>

                    </div>
                </div>

                <div className="space-y-2 w-full mt-2">
                    {editMode ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimeField
                                label='Start date and time'
                                onChange={(newDateTime) => handleDateTimeChange(newDateTime, 'start')}
                                defaultValue={dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${start}`)}
                                ampm={false} // Set to false to use 24-hour format
                            />
                            <TimeField
                                label='End time'
                                onChange={(newDateTime) => handleDateTimeChange(newDateTime, 'end')}
                                defaultValue={dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${end}`)}
                                ampm={false} // Set to false to use 24-hour format
                            />
                        </LocalizationProvider>
                    ) : (
                        <>
                            {start && <TaskField field="Start" value={start} color="red" />}
                            {end && <TaskField field="End" value={end} color="red" />}
                            {date && <TaskField field="Date" value={date} color="orange" />}
                            {/* Not showing correct date when updated. */}
                            {/* {created && <TaskField field="Created" value={created} color="orange" />} */}
                        </>
                    )}
                </div>
            </div>

            <div className="flex justify-center items-center h-8 mt-2">
                {editMode ? (
                    <IconButton onClick={saveTaskEdit}>
                        <BorderColorIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => { handleTaskUpdated(id, { 'completed': !completed }) }}>
                        {!completed ? <Done /> : <RemoveDoneIcon />}
                    </IconButton>
                )}
            </div>
        </div>

    )
}

export default TaskTile