import React from 'react'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import dayjs from "dayjs";

import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from "@mui/material/IconButton";


const TaskRow = ({ id, completed, title, description, start, end, date, created, editMode, handleClick, saveTaskEdit, setTaskTitle, setTaskDescription, updateTaskField, handleDateTimeChange, handleTaskUpdated }) => {
    return (
        <>
            <tr
                key={id}
                className={`border border-gray-300 shadow-md px-4 ${editMode ? 'bg-orange-100' : ''} ${completed ? 'bg-green-300' : 'bg-white'
                    }`}
                onDoubleClick={editMode ? null : handleClick}
            >
                {editMode ?
                    <>
                        <td className="grid-col-2 max-w-xl truncate py-1 px-2">
                            <input
                                value={title}
                                placeholder='Task Title'
                                className='rounded-md w-full'
                                onChange={(event) => { updateTaskField(event, setTaskTitle, 'title') }}
                                required
                            />
                        </td>
                        <td className="max-w-sm truncate py-1 px-2">
                            <input
                                value={description}
                                placeholder='Task Description'
                                className='rounded-md w-full'
                                onChange={(event) => { updateTaskField(event, setTaskDescription, 'description') }}
                            />
                        </td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <td>
                                <DateTimeField
                                    onChange={(newDateTime) => handleDateTimeChange(newDateTime, 'start')}
                                    defaultValue={dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${start}`)}
                                    ampm={false} // Set to false to use 24-hour format
                                    size='small'
                                />
                            </td>
                            <td>
                                <TimeField
                                    onChange={(newDateTime) => handleDateTimeChange(newDateTime, 'end')}
                                    defaultValue={dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${end}`)}
                                    ampm={false}
                                    size='small'
                                />
                            </td>
                        </LocalizationProvider>
                        <td>{date}</td>
                        {/* <td>{created}</td> */}
                        <td className='text-center'>
                            <IconButton onClick={saveTaskEdit}>
                                <BorderColorIcon />
                            </IconButton>
                        </td>
                    </>
                    :
                    <>
                        <td className="max-w-xs truncate py-1 px-2">{title}</td>
                        <td className="max-w-xs truncate py-1 px-2">{description}</td>
                        <td>{start}</td>
                        <td>{end}</td>
                        <td>{date}</td>
                        {/* Check task tile */}
                        {/* <td>{created}</td> */}
                        <td className='text-center' >{
                            <IconButton onClick={() => { handleTaskUpdated(id, { 'completed': !completed }) }}>
                                {
                                    !completed ?
                                        <CheckBoxOutlineBlankIcon /> :
                                        <CheckBoxIcon />
                                }
                            </IconButton>
                        }</td>
                    </>
                }
            </tr>
        </>
    )
}

export default TaskRow