import CircularProgress from '@mui/material/CircularProgress';

export default function CircularIndeterminate() {
  return (
    <div className="flex justify-center items-center">
      {/* Add custom styles for size or additional Tailwind classes as needed */}
      <div className="text-4xl"> {/* Adjust the size using the text scale */}
        <CircularProgress />
      </div>
    </div>
  );
}
