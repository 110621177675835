import React from 'react';

const CTA = () => (
  <section className="bg-black py-12 text-white text-center">
    <div className="container mx-auto">
      <h2 className="text-3xl font-bold mb-6">Get Started with AccioTask</h2>
      <p className="mb-6">Experience the future of task management today.</p>
      {/* <button className="bg-white text-black font-semibold py-3 px-6 rounded-lg shadow-md">
        Sign Up Now
      </button> */}
    </div>
  </section>
);

export default CTA;
