import { useState } from 'react';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddTaskIcon from '@mui/icons-material/AddTask';

const styles = {
    fab: {
        bgcolor: 'rgb(255, 255, 255)',
        '&:hover': {
            backgroundColor: 'rgba(0, 255, 0, 0.3)', // Green with 30% opacity
        },
    },
    icon: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

export default function FloatingInputButton({ setTaskInputModal }) {

    return (
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab
                sx={styles.fab}
                aria-label="Create Task"
                onClick={() => { setTaskInputModal(true) }}>
                <AddTaskIcon color='black' sx={{ color: 'green' }} />
            </Fab>
        </Box>
    );
}