import * as React from 'react';

import { BrowserRouter, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';

export default function AccountMenu({ username = 'user' }) {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/user/logout',
                {
                    withCredentials: true
                }
            );
            if (response.status === 200) {
                // On successful logout, navigate to /landing
                navigate('/landing');
            } else {
                console.error("Logout failed:", response.data);
            }
        } catch (error) {
            console.error("An error occurred during logout:", error);
        }
    };

    const handleSettings = async () => {
        try {
            navigate('/settings');
        } catch (error) {
            // Handle error.
            console.error("Error going to settings: ", error);
        }
    }

    const handleBilling = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/billing`,
                {
                    // Your data here. For example:
                    // amount: 100,
                    // currency: 'USD'
                },
                {
                    withCredentials: true
                }
            );

            if (response.status === 200) {
                // Successfully processed billing.
                // Do something with response.data if needed.
                console.log("Billing successful: ", response.data.url);
                window.location.href = response.data.url;
            }
        } catch (error) {
            // Handle error.
            console.error("Error during billing: ", error);
        }
    };


    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ backgroundColor: 'black' }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32, backgroundColor: 'black' }}>{username?.charAt(0).toUpperCase()}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem onClick={handleBilling}>
                    <ListItemIcon>
                        <AccountBalanceIcon fontSize="small" />
                    </ListItemIcon>
                    Billing
                </MenuItem> */}
                <MenuItem onClick={handleSettings}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
