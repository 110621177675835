import { useEffect, useState } from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import TaskBox from '../TaskBox';
import { parseTask } from '../../../helpers/taskParser';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
var LocalizedFormat = require('dayjs/plugin/localizedFormat')

// Use the plugins
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

const TasksTilesView = ({ taskList, setTaskUpdatedId }) => {
    const [collapsedIndexes, setCollapsedIndexes] = useState([]);
    const [categorizedTasks, setCategorizedTasks] = useState([])

    const toggleCollapse = (index) => {
        if (collapsedIndexes.includes(index)) {
            setCollapsedIndexes(collapsedIndexes.filter(i => i !== index));
        } else {
            setCollapsedIndexes([...collapsedIndexes, index]);
        }
    };

    useEffect(() => {
        const categorized = {};

        // Loop through the taskList and categorize tasks by date
        taskList.forEach(task => {
            let taskDate = task.date;

            if (categorized.hasOwnProperty(taskDate)) {
                categorized[taskDate].push(task);
            } else {
                categorized[taskDate] = [task];
            }
        });

        // Extract keys (dates) and sort them based on today's localized date
        const today = dayjs().format('L');
        const sortedKeys = Object.keys(categorized).sort((a, b) => {
            if (a === today) return -1;
            if (b === today) return 1;

            // Convert both dates to dayjs objects and compare
            return dayjs(b).toDate() - dayjs(a).toDate();
        });

        // Create a new object with sorted keys and categorize tasks using localized formats
        const sortedCategorized = {};
        sortedKeys.forEach((key) => {
            sortedCategorized[dayjs(key).format('L')] = categorized[key];
        });

        // Update state with the categorized tasks
        setCategorizedTasks(sortedCategorized);
    }, [taskList]);

    return (
        <div className='w-full h-full justify-center items-center'>
            {Object.entries(categorizedTasks).map(([date, tasks], index) => (
                <div key={date}>
                    <div
                        className='flex items-center sticky top-24 z-10 p-2 rounded-lg cursor-pointer'
                        onClick={() => toggleCollapse(index)}
                    >
                        <h2 className='text-left text-md font-semibold text-slate-600'>
                            {date === dayjs().format('L') ? 'Today' : dayjs(date).format('L')}{collapsedIndexes.includes(index) ? <ExpandMore /> : <ExpandLess />}
                        </h2>
                        <hr className='h-px my-1 bg-gray-200 border-0 dark:bg-gray-700 mx-3 flex-grow' />
                    </div>
                    <div className={`transition-all h-full ${collapsedIndexes.includes(index) ? 'h-0 opacity-0' : ''}`}>
                        {!collapsedIndexes.includes(index) && <div className='w-full p-4'>
                            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4 sm:grid-cols-3">
                                {tasks?.map((task) => {
                                    var parsedTask = parseTask(task);
                                    return (
                                        <div
                                            key={task.id}
                                            className={`w-full border border-gray-300 rounded-lg shadow-md ${task.completed ? 'bg-green-300' : 'bg-white'
                                                }`}
                                        >
                                            <TaskBox
                                                view={1}
                                                id={task.id}
                                                title={parsedTask.title}
                                                description={parsedTask.description}
                                                start={parsedTask.start}
                                                end={parsedTask.end}
                                                date={parsedTask.date}
                                                created={parsedTask.created}
                                                completed={parsedTask.completed}
                                                setTaskUpdatedId={setTaskUpdatedId}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>}
                    </div>
                </div>
            ))}
        </div>

    )
}

export default TasksTilesView