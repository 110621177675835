import React from 'react'
import { parseTask } from '../../../helpers/taskParser';
import TaskBox from '../TaskBox';

const TasksTableView = ({ taskList, setTaskUpdatedId }) => {

    return (
        <div className="w-full p-4 overflow-x-scroll sm:overflow-auto">
            <table className="w-full border-collapse text-left">
                <thead>
                    <tr>
                        <th className="grid-col-2 px-2">Title</th>
                        <th className="grid-col-1 px-2">Description</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Date</th>
                        {/* <th>Created</th> */}
                        <th>Complete</th>
                    </tr>
                </thead>
                <tbody>
                    {taskList?.map((task, index) => {
                        const parsedTask = parseTask(task);
                        return (
                            <TaskBox
                                key={task.id}
                                id={task.id}
                                title={parsedTask.title}
                                description={parsedTask.description}
                                start={parsedTask.start}
                                end={parsedTask.end}
                                date={parsedTask.date}
                                created={parsedTask.created}
                                completed={parsedTask.completed}
                                setTaskUpdatedId={setTaskUpdatedId}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TasksTableView