import dayjs from 'dayjs';

// Function to parse the SQL object into the desired JSON format

export function parseTask(task) {
    const { title, description, start_time, end_time, date, created_at, completed } = task;
    const startTime = start_time.slice(0, 5); // Extract hours and minutes from start_time
    const endTime = end_time.slice(0, 5); // Extract hours and minutes from end_time
    // console.log('parseTask', date)
    const formattedDate = new Date(date).toLocaleDateString('en-IE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
    // console.log('formattedDate', formattedDate)

    const created = new Date(created_at).toLocaleDateString('en-IE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    try {
        return {
            title,
            description,
            start: startTime,
            end: endTime,
            date: dayjs(formattedDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
            created: dayjs(created, 'DD/MM/YYYY').format('DD/MM/YYYY'),
            completed
        };
    } catch (err) {
        console.log(err)
        return task
    }
}