import './App.css';
import Service from './containers/Service';
import Landing from './containers/Landing';
import Settings from './containers/Settings';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <Router>
      <div className='App'>
        <Analytics />
        <Routes>
          <Route path="/" element={<Service />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
