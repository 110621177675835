import React, { useRef, useEffect } from 'react';
import videoSrc from '../../assets/background-video.mp4'; // Adjust the path as needed

const Background = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      if (video.duration - video.currentTime <= 0.3) {
        video.currentTime = 0; // Restart the video
        video.play();
      }
    };

    if (video) {
      video.onloadedmetadata = () => {
        video.currentTime = 0.3; // Start the video 0.3 seconds later
      };

      video.addEventListener('timeupdate', handleTimeUpdate);

      // Clean up event listener on unmount
      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, []);

  return (
    <div className="absolute top-0 left-0 -z-10 w-full h-full overflow-hidden">
      <video
        ref={videoRef}
        className="w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Background;