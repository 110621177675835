import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../logo_inverted.svg';
import { ReactComponent as Title } from '../title_inverted.svg';

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center space-x-4 px-6 h-14 bottom-0 bg-black">
            <button onClick={() => { navigate('/') }} className="flex right-0 h-8 min-w-3/5 items-center">
                {<Logo />}
                <div className="hidden sm:block">
                    {<Title />}
                </div>
            </button>
            {/* <div className="flex space-x-6 items-center">
                <div>
                    <a href="mailto:mmttlawlor26@gmail.com" className="text-white hover:text-gray-500">Contact</a>
                </div>
                <div>
                    <a href="emmettlawlor.com" className="text-white hover:text-gray-500">Meet the Creator</a>
                </div>
            </div> */}
        </div>
    );
};

export default Footer;
