import React from 'react'
import Navbar from '../components/Navbar';
import Hero from '../components/Landing/Hero'
import Features from '../components/Landing/Features';
import Benefits from '../components/Landing/Benefits';
import HowItWorks from '../components/Landing/HowItWorks';
import Testimonials from '../components/Landing/Testimonials';
import CTA from '../components/Landing/CTA';

const Landing = () => {
    return (
        <div className='w-full h-auto bg-gray-50 bg-opacity-80'>
            <div className='p-2'>
                <div className='sticky top-0 w-full'>
                    <Navbar />
                </div>
                <Hero />
                <Features />
                <HowItWorks />
                <Benefits />
                <Testimonials />
            </div>
            <CTA />
        </div>
    )
}

export default Landing