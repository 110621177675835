import { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

import { TextField } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SubmitButton from "../../Buttons/Buttons";

// Use the UTC plugin with Dayjs
dayjs.extend(utc);

const CreateStandardTask = ({ setError, setSuccess, setNewTasks, handleClose }) => {
    const now = dayjs();
    const minutes = now.minute();
    const seconds = now.second();
    const milliseconds = now.millisecond();
    const additionalMinutes = minutes < 15 ? 0 : (minutes < 45 ? 30 : 60);
    const roundedNow = now
        .add(additionalMinutes - minutes, 'minutes')
        .subtract(seconds, 'seconds')
        .subtract(milliseconds, 'milliseconds');

    const roundedTime = additionalMinutes === 60 ? roundedNow.add(1, 'hour').minute(0) : roundedNow;


    const [loading, setLoading] = useState(false);
    const [title, setTaskTitle] = useState('');
    const [description, setTaskDescription] = useState('');
    const [date, setTaskDate] = useState(roundedTime);
    const [start, setTaskStart] = useState(roundedTime);
    const [end, setTaskEnd] = useState(roundedTime.add(1, 'hour'));

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const utcStart = start.utc().format('HH:mm:ss');
            const utcEnd = end.utc().format('HH:mm:ss');

            await axios.post(`${process.env.REACT_APP_API_URL}/tasks/create`, {
                    start: utcStart,
                    end: utcEnd,
                    title,
                    description,
                    date: date.utc().format('YYYY-MM-DD'), // Converting the date to UTC as well, if needed
                },
                {
                    withCredentials: true
                })
                .then(response => {
                    const parsedTasks = [response.data.task];
                    setNewTasks(parsedTasks);
                    handleClose();
                    setSuccess(`${parsedTasks.length} new tasks created.`);
                });
        } catch (err) {
            setError("Error creating task.");
        }
        setLoading(false);
    };

    return (
        <div className="flex flex-col justify-between w-full sm:max-w-lg bg-white border-gray-300 rounded-md px-2" style={{ minHeight: '350px' }}>
            <form
                id='task-input-container'
                className="flex flex-col w-full sm:max-w-lg items-center justify-center bg-white border-gray-300 rounded-md px-2 space-y-2"
                onSubmit={handleSubmit}
            >
                <div className="flex flex-col w-full flex-grow space-y-4 justify-center items-center">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Task Title"
                        value={title}
                        onChange={(event) => setTaskTitle(event.target.value)}
                        required
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Task Description"
                        value={description}
                        onChange={(event) => setTaskDescription(event.target.value)}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Date"
                            value={date}
                            inputVariant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={(newDate) => setTaskDate(newDate)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                            label="Start Time"
                            value={start}
                            inputVariant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={(newTime) => setTaskStart(newTime ? newTime : dayjs())}
                            ampm={false}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <TimePicker
                            label="End Time"
                            value={end}
                            inputVariant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={(newTime) => setTaskEnd(newTime ? newTime : dayjs())}
                            ampm={false}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                <SubmitButton loading={loading} />

            </form>
        </div>
    )
}

export default CreateStandardTask;
