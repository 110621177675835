import CircularIndeterminate from "../Common/Common";
import AddTaskIcon from '@mui/icons-material/AddTask';

const SubmitButton = ({ loading }) => {
    return (
        <div>
            {loading ? <CircularIndeterminate /> : <button type='submit' className="flex items-center h-8 bg-green-500 text-white rounded-md px-4 py-2">
                <AddTaskIcon />
            </button>}
        </div>
    )
}

export default SubmitButton