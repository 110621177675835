import { useEffect, useState } from "react";


import DeleteIcon from '@mui/icons-material/Delete';
import Edit from "@mui/icons-material/Edit";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import TaskModal from "./TaskModal";
import TaskRow from "./TaskRow";
import TaskTile from "./TaskTile";

import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
var LocalizedFormat = require( 'dayjs/plugin/localizedFormat')

// Use the plugins
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

const convertDate = (date) => {
    const parts = date.split('/'); // Split the date string into parts
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Reorder to "YYYY-MM-DD"
};

const combineDateTime = (date, time) => {
    // Assuming date is in "YYYY-MM-DD" format and time is in "HH:mm" format
    const normalizedDate = convertDate(date)
    const dateTimeString = `${normalizedDate}T${time}:00Z`; // Construct a full ISO 8601 date-time string with "00" seconds and a "Z" to denote UTC
    return dayjs(dateTimeString).local().format('HH:mm'); // Convert to local time and format as "HH:mm"
};

const TaskBox = ({ view, id, title, start, end, date, created, description, completed, setTaskUpdatedId }) => {

    // Convert UTC to local time upon initialization
    const [startTime, setStartTime] = useState(combineDateTime(date, start));
    const [endTime, setEndTime] = useState(combineDateTime(date, end));
    const [taskDate, setTaskDate] = useState(dayjs.utc(convertDate(date)).local().format('L'));
    const [createdAt, setCreatedAt] = useState(dayjs.utc(convertDate(date)).local().format('L'));
    const [taskTitle, setTaskTitle] = useState(title);
    const [taskDescription, setTaskDescription] = useState(description);
    const [taskCompleted, setTaskCompleted] = useState(completed);

    const [newTaskUpdate, setNewTaskUpdate] = useState({});
    const [newTaskDataBaseUpdate, setNewTaskDataBaseUpdate] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [editTaskAnchorEl, setEditTaskAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setNewTaskUpdate({});
        setNewTaskDataBaseUpdate({});
    }, [editMode]);

    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null)
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTaskUpdated = (taskId, updates) => {
        console.log(updates)
        try {
            axios.put(
                `${process.env.REACT_APP_API_URL}/tasks/${taskId}`,
                updates, // Pass the updates object directly
                {
                    withCredentials: true
                }
            ).then(() => {
                setTaskUpdatedId(taskId);
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleTaskDelete = (id) => {
        try {
            axios.delete(
                `${process.env.REACT_APP_API_URL}/tasks/${id}`,
                {
                    withCredentials: true
                }
            ).then(() => {
                setTaskUpdatedId(id)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const handleDateTimeChange = (newDateTime, field = 'end') => {
        if (newDateTime) {
            // Send this data to database
            const newUTCDateTime = newDateTime.utc(); // .local()
            const newUTCDate = newUTCDateTime.format('MM-DD-YYYY');
            const newUTCTime = newUTCDateTime.format('HH:mm');

            // Display this data to user
            const newLocalDate = newDateTime.local()
            const newDate = newLocalDate.format('L');
            const newTime = newLocalDate.format('HH:mm');

            setTaskDate(newDate);

            if (field === 'start') {
                setStartTime(newTime);
                setNewTaskUpdate({ ...newTaskUpdate, 'date': newDate, 'start_time': newTime });
                setNewTaskDataBaseUpdate({ ...newTaskDataBaseUpdate, 'date': newUTCDate, 'start_time': newUTCTime });
            } else {
                setEndTime(newTime);
                setNewTaskUpdate({ ...newTaskUpdate, 'end_time': newTime });
                setNewTaskDataBaseUpdate({ ...newTaskDataBaseUpdate, 'end_time': newUTCTime });
            }
        }
    };

    const handleTaskEdit = () => {
        setEditMode(!editMode)
    }

    const saveTaskEdit = () => {
        // update task in database
        if (Object.keys(newTaskUpdate).length !== 0) {
            handleTaskUpdated(id, newTaskDataBaseUpdate)
        }
        setEditMode(false)
    }

    const updateTaskField = (event, setFunction, field) => {
        setFunction(event.target.value); 
        setNewTaskUpdate({ ...newTaskUpdate, [field]: event.target.value });
        setNewTaskDataBaseUpdate({ ...newTaskUpdate, [field]: event.target.value });
    }

    return (
        <>
            {view === 1 ?
                (<TaskTile
                    id={id}
                    completed={completed}
                    title={taskTitle}
                    description={taskDescription}
                    start={startTime}
                    end={endTime}
                    date={taskDate}
                    created={createdAt}
                    editMode={editMode}
                    handleClick={handleClick}
                    saveTaskEdit={saveTaskEdit}
                    setTaskTitle={setTaskTitle}
                    setTaskDescription={setTaskDescription}
                    updateTaskField={updateTaskField}
                    handleDateTimeChange={handleDateTimeChange}
                    handleTaskUpdated={handleTaskUpdated}
                />)
                :
                (<TaskRow
                    id={id}
                    completed={completed}
                    title={taskTitle}
                    description={taskDescription}
                    start={startTime}
                    end={endTime}
                    date={taskDate}
                    created={createdAt}
                    editMode={editMode}
                    handleClick={handleClick}
                    saveTaskEdit={saveTaskEdit}
                    setTaskTitle={setTaskTitle}
                    setTaskDescription={setTaskDescription}
                    updateTaskField={updateTaskField}
                    handleDateTimeChange={handleDateTimeChange}
                    handleTaskUpdated={handleTaskUpdated}
                />)}
            <TaskModal
                id={id}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                handleTaskEdit={handleTaskEdit}
                handleTaskDelete={handleTaskDelete}
            />
        </>
    );
};

export default TaskBox;
