import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, IconButton } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';

import Navbar from '../components/Navbar';

import validateEmail from '../helpers/email'
import CircularIndeterminate from '../components/Common/Common';
import ErrorMessage from '../components/Messages/ErrorMessage';
import SuccessMessage from '../components/Messages/SuccessMessage';
import { ArrowBack } from '@mui/icons-material';

const SettingsComponent = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [icsEnabled, setIcsEnabled] = useState(false);
    const [username, setUsername] = useState('');
    const [hasPremium, setHasPremium] = useState(false);
    const [isModified, setIsModified] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [emailError, setEmailError] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    useEffect(() => {
        const fetchUserPreferences = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/settings`, {
                    withCredentials: true
                });
                if (response.status === 200) {
                    const { userEmail, username, premium, ics_enabled } = response.data;
                    setEmail(userEmail);
                    setUsername(username);
                    setHasPremium(premium);
                    // setMobile(mobile);
                    setIcsEnabled(ics_enabled);
                }
            } catch (error) {
                navigate('/')
                console.error('Error fetching user preferences:', error);
            }
            setIsLoading(false)
        };
        fetchUserPreferences();
    }, []);

    const handleSave = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/settings/update`, {
                email,
                ics_enabled: icsEnabled,
            });
            if (response.status === 200) {
                setIsModified(false);
                // Optionally, show a success message
                setSuccess('Your settings have been saved.')
            } else {
                setError('Your settings have not been saved. Please try again.')
            }
        } catch (error) {
            console.error('Error saving preferences:', error);
            setError('Your settings have not been saved. Please try again.')
        }
    };

    const handleCancelSubscription = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/cancel-subscription`, {
                withCredentials: true,
            });
            if (response.status === 200) {

                const logoutResponse = await axios.post(process.env.REACT_APP_API_URL + '/user/logout',
                    {
                        withCredentials: true
                    }
                );

                if (logoutResponse.status === 200) {
                    // On successful logout, navigate to /landing
                    navigate('/landing');
                } else {
                    console.error("Logout failed:", response.data);
                }
                navigate('/')
            }
        } catch (error) {
            console.error('Error cancelling subscription:', error);
        }
    }

    const toggleIcsEnabled = () => {
        setIcsEnabled(!icsEnabled);
        setIsModified(true);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setIsModified(true);

        // Simple regex pattern for validating email addresses
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };

    const handleBack = () => {
        navigate('/')
    }

    return (
        <>
            {error && <ErrorMessage message={error} setMessage={setError} />}
            {success && <SuccessMessage message={success} setMessage={setSuccess} />}
            <div className='flex flex-col h-screen'>
                <Navbar username={username} premium={hasPremium} />
                <div className='relative flex flex-col justify-center items-center mt-16 p-4'>
                    <div className="absolute top-0 left-0 z-10 p-4">
                        <IconButton onClick={handleBack}>
                            <ArrowBack />
                        </IconButton>
                    </div>
                    {isLoading ? <CircularIndeterminate /> :
                        <div className='p-6 max-w-lg w-full'>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={username}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            {/* <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={email}
                                error={Boolean(emailError)}
                                helperText={emailError}
                                onChange={handleEmailChange}
                            /> */}
                            <FormControlLabel
                                control={<Checkbox checked={icsEnabled} onChange={toggleIcsEnabled} />}
                                label='Enable emails for integration with an external calendar.'
                                style={{ margin: '16px 0' }}
                            />
                            <p className='text-left text-sm'>By enabling this, you will receive an email every time you create, edit or delete a task on AccioTask. We email you a small file which your email client (Google, Outlook, etc.) can read and put onto the calendar associated with your email.</p>
                            <div className="flex flex-col w-auto p-4">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isModified}
                                    onClick={handleSave}
                                    style={{ marginTop: '16px' }}
                                >
                                    Save Changes
                                </Button>
                                {/* {
                                    hasPremium && (
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: red[500] }}
                                            onClick={handleCancelSubscription}
                                            style={{ marginTop: '16px' }}
                                        >
                                            Cancel Subscription
                                        </Button>
                                    )
                                } */}
                            </div>
                        </div>}
                </div>
            </div>
        </>
    );
};

export default SettingsComponent;
