import React from 'react';

const Testimonials = () => (
  <section className="py-12">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-6">What Our Users Say</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-2">
        <div className="p-6 bg-white rounded-lg shadow-md">
          <p className="italic">"I built AccioTask with simplicity in mind, I found it hard to sit down and plan my week, now it's like texting a personal assistant that fills my calendar for me."</p>
          <h3 className="text-lg font-semibold mt-4">- Emmett Lawlor, Founder</h3>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-md">
          <p className="italic">"A game-changer for task management with seamless calendar integration."</p>
          <h3 className="text-lg font-semibold mt-4">- Tanmay Bhosale, Software Developer</h3>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-md">
          <p className="italic">"I recieve 100s of text messages per week, AccioTask is great as I can just copy and paste these in and they go onto my company calendar."</p>
          <h3 className="text-lg font-semibold mt-4">- Lauren Rached, Business Owner</h3>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-md">
          <p className="italic">"It helps a lot when trying to stay on top of properties I host, having a place to log all the stays, cleanings and things outside of work is great."</p>
          <h3 className="text-lg font-semibold mt-4">- Johnny Bates, AirBnB Host</h3>
        </div>
      </div>
    </div>
  </section>
);

export default Testimonials;
