import { useEffect, useState } from 'react';
import axios from 'axios';

import { Modal } from '@mui/material';

import ErrorMessage from '../Messages/ErrorMessage';
import CircularIndeterminate from '../Common/Common';
import SuccessMessage from '../Messages/SuccessMessage';
import CreateAITask from '../Tasks/Forms/CreateAITask';
import CreateStandardTask from '../Tasks/Forms/CreateStandardTask';

const InputBar = ({ open, handleClose, setNewTasks, formPreference }) => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [formType, setFormType] = useState('standard') // get this from userPreferences

    useEffect(() => {
        setFormType(formPreference)
    }, [formPreference])

    return (
        <>
            {
                error && <ErrorMessage message={error} />
            }
            {
                success && <SuccessMessage message={success} setMessage={setSuccess} />
            }
            <Modal
                open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        handleClose();
                    }
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5%',
                    alignItems: 'flex-start',
                    '@media (max-width: 600px)': {
                        alignItems: 'flex-start',
                        marginTop: '15%',
                    },
                    paddingX: '15px'
                }}
            >
                <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full sm:max-w-lg sm:w-full"> {/* Adjust the max-width as needed */}
                    <div className="flex justify-center items-center bg-gray-200 p-2 rounded-t-lg space-x-2"> {/* Keep the top rounded corners */}
                        {/* Standard option */}
                        <button
                            className={`${formType === 'standard' ? 'bg-blue-500 text-white' : 'text-gray-600'
                                } py-2 px-4 rounded-lg focus:outline-none`}
                            onClick={() => setFormType('standard')}
                        >
                            Standard
                        </button>

                        {/* Magic AI option */}
                        <button
                            className={`${formType === 'ai' ? 'bg-gradient-to-r from-purple-500 to-blue-500 text-white' : 'text-gray-600 bg-gradient-to-r from-orange-300 to-orange-500'
                                } py-2 px-4 rounded-lg focus:outline-none shadow-md transition duration-300 ease-in-out transform hover:scale-110 hover:bg-purple-400 hover:text-white`}
                            onClick={() => setFormType('ai')}
                        >
                            Magic AI
                        </button>
                    </div>
                    <div className="p-6"> {/* Apply padding here for the content */}
                        {
                            formType === 'standard' ?
                                (<CreateStandardTask setError={setError} setSuccess={setSuccess} setNewTasks={setNewTasks} handleClose={handleClose} />)
                                :
                                (<CreateAITask setError={setError} setSuccess={setSuccess} setNewTasks={setNewTasks} handleClose={handleClose} />)
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default InputBar;
